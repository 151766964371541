import * as React from 'react';
import * as Styled from './ColorSample.style';

export interface IColorSampleProps {
  color: string;
  name: string;
}

const ColorSample: React.FC<IColorSampleProps> = ({ color, name }) => (
  <Styled.Wrapper>
    <Styled.ColorSample color={color}>
      <Styled.ColorName>{name}</Styled.ColorName>
    </Styled.ColorSample>
    <Styled.ColorValue>{color}</Styled.ColorValue>
  </Styled.Wrapper>
);

export default ColorSample;
