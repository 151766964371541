import * as React from 'react';
import styled, { ThemeConsumer } from 'styled-components';
import Layout from '../components/Layout';
import Container from '../components/Container';
import ColorSample from '../components/ColorSample';

const ColorSwatches = styled.div`
  display: flex;
`;

const StyleGuide: React.FC = props => (
  <Layout>
    <Container>
      <h1>Colors</h1>
      <ColorSwatches>
        <ThemeConsumer>
          {theme =>
            Object.keys(theme.colors).map(colorName => <ColorSample color={theme.colors[colorName]} name={colorName} />)
          }
        </ThemeConsumer>
      </ColorSwatches>
    </Container>
  </Layout>
);

export default StyleGuide;
