import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1 0 auto;
  max-width: 140px;
  margin-bottom: 30px;
`;

export const ColorSample = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
  background-color: ${props => props.color};
`;

export const ColorName = styled.span`
  margin: 40px 0;
  padding: 4px;
  background: #fff;
  outline: 1px solid ${props => props.theme.colors.black};
`;

export const ColorValue = styled.div`
  text-align: center;
`;
